<template>
  <div class="CategoryFragment mx-auto mt-17 md:mt-32">
    <!-- <h2 class="px-5 md:px-0 text-primary-1-100 font-semibold md:text-center text-xl-1 md:text-4xl whitespace-nowrap">
      {{ t('title') }}
    </h2> -->

    <ClientOnly>
      <Swiper
        :modules="[SwiperNavigation]"
        :slides-per-view="itemsPerRowOnMobile"
        :space-between="15"
        :slides-offset-before="20"
        :scrollbar="{ el: '.swiper-scrollbar', hide: false }"
        :breakpoints="{
          '768': {
            slidesPerView: 3,
            spaceBetween: 20,
            centeredSlidesBounds: false,
            centerInsufficientSlides: false,
            slidesPerGroup: 4.4,
          },
          '1024': {
            slidesPerView: itemsPerRowOnDesktop,
            spaceBetween: 20,
            centeredSlidesBounds: true,
            centerInsufficientSlides: true, // !!(props.alignment === 'center'),
            centeredSlides: true,
            slidesOffsetBefore: 0,
            slidesPerGroup: 5,
            scrollbar: { el: '.swiper-scrollbar', hide: true },
          },
        }"
        class="mt-10 swiper-container w-full"
      >
        <SwiperSlide v-for="category in categories" :key="category.id || ''">
          <AppLink :to="`/${category.url_key}`" class="swiper-slide relative flex justify-center flex-col w-44 h-full">
            <NuxtImg
              class="CategoryImage w-full"
              format="webp"
              :src="category.image"
              :alt="category.name"
              width="450"
              height="599"
              loading="lazy"
            />
            <span class="block text-primary-1-100 text-sm md:text-md font-bold text-center mt-3">
              {{ category.name }}
            </span>
          </AppLink>
        </SwiperSlide>

        <!-- <SwiperSlide>
          <AppLink to="/deals" class="swiper-slide relative flex flex-col w-full h-full">
            <img src="/deals.jpg" alt="" class="w-full h-full rounded-md" width="164" height="224" />
            <span
              class="[ mt-5 w-full text-black text-sm text-center uppercase tracking-widest ] [ flex justify-center ]"
            >
              {{ t('deals') }}
            </span>
          </AppLink>
        </SwiperSlide> -->
        <!-- <div class="swiper-scrollbar"></div> -->
      </Swiper>
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' });

const props = defineProps({
  arrows: {
    type: Boolean,
    default: false,
  },
  itemsPerRowOnDesktop: {
    type: Number,
    default: 4,
  },
  itemsPerRowOnMobile: {
    type: Number,
    default: 2.4,
  },
  alignment: {
    type: String,
    default: 'start',
    validator: (value: string) => ['start', 'center', 'end'].includes(value),
  },
});
const { megaMenuCategories: categories } = await useHomeFeaturedCategories({
  flat: true,
});
</script>
<style lang="postcss" scoped>
.deals-bullet {
  z-index: 0;
  & > img {
    z-index: -1;
    aspect-ratio: 0.73;
  }
}

.categories-flex {
  @screen md {
    @apply flex flex-wrap;

    & > div,
    & > a {
      @apply px-3;
      height: 240px;
      flex-basis: calc(100% / 6);
    }
  }
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  bottom: 3px;
  left: 5%;
  z-index: 50;
  height: 2px;
  width: 98%;

  @media (min-width: 1024px) {
    width: 100%;
    left: 0;
  }

  & > .swiper-scrollbar-drag {
    background: black !important;
  }
}

.CategoryFragment {
  @screen md {
    margin-inline: 4%;
  }
}
.CategoryImage {
  aspect-ratio: 0.68;

  @screen md {
    aspect-ratio: 0.75;
  }
}
</style>
<i18n>
{
  "en": {
    "title": "Our Featured Categories",
    "deals": "Best Deals",
    "subtitle": "- Browse"
  },
  "ar": {
    "title": "فئاتنا",
    "deals": "أفضل العروض",
    "subtitle": "- تصفح"
  }
}
</i18n>
